@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-link {
    @apply text-blue-600 hover:text-green-600;
  }

  .table-row-bg {
    @apply odd:bg-white even:bg-gray-50
  }

  .bg-blurred {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

.max-app-width {
  @media (min-width: 1024px) {
    max-width: calc(100vw - 300px) !important;
  }
}

.max-app-width-second-menu {
  @media (min-width: 1024px) {
    max-width: calc(100vw - 524px) !important;
  }
}



.fancybox-content {
  display: inline-block !important;
}

textarea:focus {
  box-shadow: none;
}

.default-list-style ol, .default-list-style ul {
  list-style: revert !important;
  margin: revert !important;
  padding: revert !important;
}

.scrollbar-w-none {
  scrollbar-width: none;
}

.scrollbar-w-none::-webkit-scrollbar {
  display: none;
}

.mass-dropdown-visible th[mass-hide-header] {
  visibility: hidden;
}

.grid-tw {
  display: grid !important;
}

.font-medium-tw {
  font-weight: 500 !important;
}

.message-container:focus .delete-button {
  @media screen and (max-width: 640px) {
    opacity: 1 !important;
  }
}

.input-clear-button-padding input {
  padding-right: 35px !important;
}

.mask-bottom {
  --mask: linear-gradient(to bottom,
          rgba(0,0,0, 1) 0,
          rgba(0,0,0, 1) 50%,
          rgba(0,0,0, 0) 95%,
          rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;

  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.container__months {
  margin: 0 auto;
}

.radio, .radio-inline {
  label:not(.radio-list) {
    input[type="radio"], input[type="checkbox"] {
      position: absolute;
      left: 0;
      top: 2px;
      opacity: 0;
    }

    span {
      display: flex;
      align-items: center;
    }
  }
}

.capitalize-first:first-letter {
  text-transform: uppercase;
}

.aspect-square {
  aspect-ratio: 1 / 1 !important;
}
